<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        class="col-12"
        label="File Url"
        v-model="formData.url"
        :validations="[
          {text: 'required!', value: $v.formData.url.$dirty && !$v.formData.url.required}
        ]">
      </sgv-input-text>
    </div>

    <button
      type="button"
      class="btn btn-warning"
      @click="sendMail">
      Send Mail
    </button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { PRINT_DEVICE } from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        url: ''
      }
    }
  },
  validations: {
    formData: {
      url: { required },
    }
  },
  methods: {
    sendMail () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: PRINT_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          url: this.formData.url
        }
      }).then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success('success')
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
