<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <sgv-form
          :method.sync="method"
          :options="options">
          <DetailForm
            :formData="formData"
            :deviceType="deviceType"
            :templateType="templateType"
            :deviceId="deviceId"
            :group="group"
            :method="method"
            :v="$v">
          </DetailForm>
        </sgv-form>
      </div>
    </div>

    <div
      class="row"
      v-if="formData.printerEmail && formData.deviceEmailId && method === 'info'">
      <div class="col-12">
        <DetailPrintFile
          :deviceType="deviceType"
          :templateType="templateType"
          :deviceId="deviceId">
        </DetailPrintFile>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import DetailForm from './DetailForm.vue'
import DetailPrintFile from './DetailPrintFile.vue'
import {
  DETAIL_DEVICE,
  CREATE_DEVICE,
  UPDATE_DEVICE,
  DESTROY_DEVICE
} from './graph/device'

export default {
  components: {
    DetailForm,
    DetailPrintFile
  },
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}-${this.formData.code || 'รายการ'}`
    }
  },
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Device${this.$form.capitalize(this.deviceType)}List`,
      rKey: `Device${this.$form.capitalize(this.deviceType)}Detail`,
      formData: {
        code: '',
        name: '',
        isActive: true,
        printerEmail: '',
        deviceEmailId: null,
        clientId: '',
        clientSecret: '',
        printerId: ''
      }
    }
  },
  validations: {
    formData: {
      code: { required },
      name: { required },
      printerEmail: { required, email },
      deviceEmailId: { required },
      clientId: { required },
      clientSecret: { required },
      printerId: { required },
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: !this.$auth.hasRole(`device:${this.deviceType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: !this.$auth.hasRole(`device:${this.deviceType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'},
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.device)
      })
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const obj = {
        code: v.code,
        name: v.name,
        isActive: v.isActive,
        printerEmail: v.printerEmail,
        deviceEmailId: v.deviceEmailId,
        clientId: v.clientId,
        clientSecret: v.clientSecret,
        printerId: v.printerId
      }

      return obj
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, deviceId: res.data.createDevice.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateDevice)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.listView) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.deviceId === 0) {
      this.method = 'add'
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
